import React from 'react';
import { 
  Container,
  Paper,
  Typography,
  makeStyles,
  Box
} from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  icon: {
    fontSize: 64,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(3),
  },
  message: {
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  spamNote: {
    color: theme.palette.text.secondary,
    fontSize: '0.9em',
    textAlign: 'center',
    marginTop: theme.spacing(2),
  }
}));

const ConfirmationPage = () => {
  const classes = useStyles();

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5" gutterBottom>
          Check Your Email
        </Typography>

        <Box display="flex" justifyContent="center" mb={3}>
          <EmailIcon className={classes.icon} />
        </Box>

        <Typography variant="body1" className={classes.message}>
          We've sent you an email with instructions to activate your account.
        </Typography>

        <Typography variant="body1" className={classes.message}>
          Please check your inbox and click the "Activate Account" button in the email.
        </Typography>

        <Typography variant="body2" className={classes.spamNote}>
          If you don't see the email, please check your spam folder.
        </Typography>
      </Paper>
    </Container>
  );
};

export default ConfirmationPage;

import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { 
  TextField,
  Button,
  Grid,
  Typography,
  Container,
  Paper,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
    backgroundColor: theme.palette.error.light,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
  }
}));

export default function Form({ user }) {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [serverError, setServerError] = useState(null);
  
  const { 
    control, 
    handleSubmit, 
    formState: { errors },
    watch,
    setError 
  } = useForm();

  const csrfToken = document.querySelector('[name="csrf-token"]')?.content;

  const validateEmail = (email) => {
    return new Promise((resolve) => {
      if (!email) {
        resolve(false);
        return;
      }
      
      $.ajax({
        url: '/check_email',
        method: 'GET',
        headers: {
          'X-CSRF-Token': csrfToken
        },
        data: { email },
        success: (response) => resolve(response.authorized),
        error: () => resolve(false)
      });
    });
  };

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      setServerError(null);

      const isAuthorized = await validateEmail(data.email);
      if (!isAuthorized) {
        setError('email', { 
          type: 'manual', 
          message: 'Sign up is currently only available for authorized domains.' 
        });
        setIsSubmitting(false);
        return;
      }

      $.ajax({
        url: '/users',
        method: 'POST',
        dataType: 'json',
        headers: {
          'X-CSRF-Token': csrfToken
        },
        data: {
          user: {
            email: data.email,
            password: data.password,
            password_confirmation: data.passwordConfirmation,
            first_name: data.firstName,
            last_name: data.lastName,
            user_company_id: user.user_company_id,
            expiration_date: user.expiration_date
          }
        },
        success: () => {
          window.location.href = '/signup_confirmation';
        },
        error: (xhr) => {
          setServerError(xhr.responseJSON?.error || 'An error occurred during signup');
          setIsSubmitting(false);
        },
        complete: () => {
          setIsSubmitting(false);
        }
      });
      
    } catch (error) {
      setServerError('An error occurred during signup');
      setIsSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="sm">
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h5">
          Create Account
        </Typography>
        
        {serverError && (
          <div>
            {serverError}
          </div>
        )}

        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="firstName"
                control={control}
                rules={{ required: 'First name is required' }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label="First Name"
                    error={!!errors.firstName}
                    helperText={errors.firstName?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Controller
                name="lastName"
                control={control}
                rules={{ required: 'Last name is required' }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label="Last Name"
                    error={!!errors.lastName}
                    helperText={errors.lastName?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="email"
                control={control}
                defaultValue=""
                rules={{ 
                  required: 'Email is required',
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address'
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    label="Email"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="password"
                control={control}
                defaultValue=""
                rules={{ 
                  required: 'Password is required',
                  minLength: {
                    value: 8,
                    message: 'Password must be at least 8 characters'
                  }
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    type="password"
                    label="Password"
                    error={!!errors.password}
                    helperText={errors.password?.message}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="passwordConfirmation"
                control={control}
                defaultValue=""
                rules={{
                  required: 'Please confirm your password',
                  validate: value => value === watch('password') || 'Passwords do not match'
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    fullWidth
                    type="password"
                    label="Confirm Password"
                    error={!!errors.passwordConfirmation}
                    helperText={errors.passwordConfirmation?.message}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            className={classes.submit}
          >
            {isSubmitting ? 'Creating Account...' : 'Create Account'}
          </Button>
        </form>
      </Paper>
    </Container>
  );
};

